import './App.css';
import {useRef} from 'react' 

function App() {

  const counter = useRef(0)

  const bookAppointment = (e) => {
    window.dataLayer.push({
      event: 'appointmentBooked',
      data: {
        appointmentId: counter.current,
      }
    });

    counter.current += 1

  }


  
  return (
    <>
        <p>
          Google ads check
        </p>
        <button onClick={bookAppointment}>book</button>
      </>
  );
}

export default App;
